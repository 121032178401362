import { i18n } from "@/main";
import { DBOfflineDataStore, ObservationSchemaReq, ObservationsUploadApiException } from "./types";
import { OfflineDB } from "./offline-db";
import { PlantSchema } from "../api-schemas/plant-schema";
import { volateqApi } from "../volateq-api";
import { DBOfflineStore } from "./offline-store";
import { VolateqOfflineApi } from "./volateq-offline-api";
import { DBOfflineStores } from "./offline-stores";
import { ApiException } from "../api-errors";
import { hash } from "../../helper/string-helper";
import { dataURLtoFile } from "../../helper/file-helper";
import { store } from "@/app/shared/stores/store";
import { CreatedObservationSchema } from "../api-schemas/observation-schema";


export abstract class OfflineApiBase {
  protected offlineDataStores: DBOfflineDataStore<any>[] = [];
  protected offlineDB: OfflineDB | null = null;

  constructor(
    protected readonly plant: PlantSchema | null,
  ) {}

  public async goOffline(
    eventLogCallback?: (msg: string) => void
  ) {
    if (store.offline.isOffline) {
      throw new Error("Ooops! Cannot go offline, because you are already offline...");
    }

    this.mergeOfflineDataStores(VolateqOfflineApi.getCommon().offlineDataStores);

    eventLogCallback && eventLogCallback(i18n.t("create-local-database").toString());

    const openDbResult = await this.openOfflineDB();
    if (openDbResult.existed) {
      if (await this.hasObservationsToUpload()) {
        throw new Error("Cannot go offline. Upload your local Observations, before!");
      }

      await this.offlineDB!.deleteDB();

      this.offlineDB = await OfflineDB.create(this.offlineDataStores.map(s => s.store));
    }

    eventLogCallback && eventLogCallback(i18n.t("save-downloaded-data").toString());
    
    await this.download();

    store.offline.goOffline(this.plant);
  }

  public async resumeOffline() {
    if (!store.offline.isOffline) {
      throw new Error("Ooops! Cannot resume to the Offline Mode, because you are not offline...");
    }

    this.offlineDB = this.offlineDB || await OfflineDB.open();
    if (!this.offlineDB) {
      await this.goOnline("Critical Error! Cannot found your offline data! Trying to leave the offline mode... ");
    }
  }

  public async goOnline(
    emergancyMsg = "",
    eventLogCallback?: (msg: string) => void,
    skipUpload = false,
  ) {
    if (!store.offline.isOffline) {
      throw new Error(emergancyMsg + "Ooops! Cannot go online, because you are already online.");
    }

    if (!(await this.hasAuthNetwork())) {
      throw new Error(emergancyMsg + "Ooops! Cannot go online. No internet connection or invalid authentication.");
    }

    this.offlineDB = this.offlineDB || await OfflineDB.open();
    if (this.offlineDB) {
      if (!skipUpload) {
        await this.tryUpload(eventLogCallback);

        eventLogCallback && eventLogCallback(i18n.t("delete-local-database").toString());

        await this.deleteDB();
      }
    } else {
      emergancyMsg += "It seems like the local database in your browser has been removed. " +
        "Please, contact the support of Volateq. " +
        "Reload the page to continue...";
    }

    store.offline.goOnline();

    if (emergancyMsg) {
      throw new Error(emergancyMsg);
    }
  }

  public async tryUpload(eventLogCallback?: (msg: string) => void) {
    try {
      await this.upload(eventLogCallback);
    } catch (e) {
      throw { ...(e as ApiException), isObservationUploadError: true } as ObservationsUploadApiException;
    }
  }

  public async deleteDB() {
    await this.offlineDB?.deleteDB();
    this.offlineDB = null;
  }

  public async upload(eventLogCallback?: (msg: string) => void) {
    const observationsToUpload = await this.getAllNewObservations();

    eventLogCallback && eventLogCallback(i18n.t("upload-observations", { count: observationsToUpload.length }).toString());

    const createdObservations = await volateqApi.addObservations(
      this.plant!.id,
      { observations: observationsToUpload.map(o => o.request) }
    );

    await this.uploadImages(observationsToUpload, createdObservations, eventLogCallback);
  }

  private async uploadImages(
    observations: ObservationSchemaReq[],
    createdObservations: CreatedObservationSchema[],
    eventLogCallback?: (msg: string) => void,
  ) {
    const images: Record<string, { img: string, name: string }> = {};
    for (const observation of observations) {
      if (observation.image_urls && observation.image_urls.length > 0) {
        for (const imageUrl of observation.image_urls) {
          const fileId = hash(imageUrl);
          images[fileId] = { img: imageUrl, name: observation.files_map![fileId] };
        }
      }
    }

    const imagesCount = Object.keys(images).length;
    if (imagesCount > 0) {
      eventLogCallback && eventLogCallback(
        i18n.t("upload-images-progress", { count: imagesCount, progress: 0 }
      ).toString());

      const uploadErrors: { e: ApiException, filenames: string[] }[] = [];

      const createdObservationsWithImages = createdObservations.filter(o => o.file_ids && o.file_ids.length > 0);
      let i = 1;
      for (const createdObservation of createdObservationsWithImages) {
        const files: File[] = createdObservation.file_ids!
          .map(imageId => dataURLtoFile(images[imageId].img, images[imageId].name));

        try {
          await volateqApi.uploadObservationImages(
            this.plant!.id,
            createdObservation.id, 
            files,
            createdObservation.component_id,
            (e) => {
              eventLogCallback && eventLogCallback(
                i18n.t("upload-images-progress",
                { 
                  count: imagesCount,
                  progress: Math.round(e.loaded / (e.total || e.loaded) * (i / createdObservationsWithImages.length) * 100),
                }
              ).toString());
            }
          );
        } catch (e) {
          uploadErrors.push({
            e: e as ApiException,
            filenames: files.map(f => f.name),
          });
        }

        i++;
      }

      if (uploadErrors.length > 0) {
        eventLogCallback && eventLogCallback(
          "ERROR " + i18n.t("cannot-upload-images").toString() + ": " + 
            uploadErrors.map(e => e.filenames.join()).join() + 
            ". Details: " + uploadErrors.map(e => e.e.error + " " + e.e.message).join(";")
        );
      }
    }
  }

  private async openOfflineDB(): Promise<{ existed: boolean }> {
    if (this.offlineDB) {
      return { existed: true };
    }

    this.offlineDB = await OfflineDB.open();
    if (this.offlineDB) {
      return { existed: true };
    }
    
    this.offlineDB = await OfflineDB.create(this.offlineDataStores.map(s => s.store));
    
    return { existed: false };
  }

  private async download() {
    for (const offlineStore of this.offlineDataStores) {
      const multiple = offlineStore.store.storeParameters.keyPath && Array.isArray(offlineStore.data) || false;
      
      if (offlineStore.data !== undefined) {
        await this.offlineDB!.add(offlineStore.store, offlineStore.data, multiple);
      }
    }
  }

  public async hasObservationsToUpload(): Promise<boolean> {
    return (await this.countOfAllNewObservations()) > 0;
  }

  public async countOfAllNewObservations(): Promise<number> {
    return (await this.getAllNewObservations()).length
  }

  private async getAllNewObservations(): Promise<ObservationSchemaReq[]> {
    if (!this.offlineDB) {
      this.offlineDB = await OfflineDB.open();
      if (!this.offlineDB) {
        return [];
      }
    }

    const newObservStoreCompIds = this.offlineDB.objectStores
      .filter(s => s.startsWith(DBOfflineStores.NEW_OBSERVATIONS_))
      .map(s => s.replace(DBOfflineStores.NEW_OBSERVATIONS_, ""));
    
    let observations: ObservationSchemaReq[] = [];
    for (const compId of newObservStoreCompIds) {
      observations = [...observations, ...(await this.offlineDB.getAll(DBOfflineStores.NEW_OBSERVATIONS(Number(compId))))];
    }

    return observations;
  }

  private async hasAuthNetwork(): Promise<boolean> {
    return await volateqApi.isLoggedIn();
  }

  private mergeOfflineDataStores(addOfflineDataStores: DBOfflineDataStore<unknown>[]) {
    this.offlineDataStores = [
      ...this.offlineDataStores.filter(s => !addOfflineDataStores.find(os => os.store.name === s.store.name)),
      ...addOfflineDataStores,
    ];
  }

  public addAndGetOfflineDataStore<T>(store: DBOfflineStore<T>): DBOfflineDataStore<T> {
    let offlineDataStore: DBOfflineDataStore<T> | undefined = this.offlineDataStores.find(s => s.store.name === store.name);
    if (!offlineDataStore) {
      offlineDataStore = { data: undefined, store };
      this.offlineDataStores.push(offlineDataStore);
    }

    return offlineDataStore;
  }

  protected async get<T, R = T>(
    plantId: string | null,
    offlineStore: DBOfflineStore<R>,
    storeDataFunc: () => Promise<R>,
    keyValue?: string,
    getDataFunc?: (d: R) => T,
    indexName?: string,
  ): Promise<T> {
    if (plantId && this.plant && this.plant.id !== plantId) {
      throw new Error("Offline store API is of another plant!")
    }

    if (!getDataFunc) {
      getDataFunc = (d) => d as unknown as T;
    }

    // Don't save data for filtered requests
    const offDataStore: DBOfflineDataStore<R> | undefined = keyValue ? undefined : this.addAndGetOfflineDataStore(offlineStore);

    const data = (store.offline.isOffline ?
      await this.getOfflineData(offlineStore, keyValue, indexName) :
      await storeDataFunc()) as R;

    if (offDataStore) {
      offDataStore.data = data;
    }

    return getDataFunc(data);
  }

  protected async getOfflineData<T>(offlineStore: DBOfflineStore<T>, keyValue?: string, indexName?: string): Promise<T> {
    try {
      if (!this.offlineDB) {
        throw new Error("Offline database disappeared!")
      }
  
      if (this.offlineDB.objectStores.includes(offlineStore.name)) {
        if (keyValue) {
          if (indexName) {
            return this.asArray(await this.offlineDB.getByIndex(offlineStore, indexName, keyValue));
          }
  
          return this.asArray(await this.offlineDB.get(offlineStore, keyValue));
        }
  
        return await this.offlineDB.getAll(offlineStore);
      }
  
      throw new Error("Store with name \"" + offlineStore.name + "\" does not exist");
    } catch (e) {
      console.error("Error while getOfflineData for store " + offlineStore.name);
      console.error(e);

      store.offline.offlineError((e as Error).toString());

      throw e;
    }
  }

  protected asArray(item: any): any {
    if (Array.isArray(item)) {
      return item;
    }

    if (item === undefined || item === null) {
      return [];
    }

    return [item];
  }
}