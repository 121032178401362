import { DBOfflineStore } from "./offline-store";
import { Unarray } from "./types";



export class OfflineDB {
  private static readonly DATABASE_NAME = "volateqOfflineDB";

  constructor(
    public readonly db: IDBDatabase,
  ) {}

  public static create(offlineStores: DBOfflineStore<unknown>[]): Promise<OfflineDB | null> {
    return new Promise((resolve, reject) => {
      let existed = true;
      const request = window.indexedDB.open(this.DATABASE_NAME);
      
      request.onerror = (e) => {
        console.error(e);

        reject(e);
      };

      request.onupgradeneeded = (e) => {
        existed = false;

        const db = (e.target as any).result as IDBDatabase;
        
        for (const offStore of offlineStores) {
          const objectStore = db.createObjectStore(offStore.name, offStore.storeParameters);
          if (offStore.indexes) {
            for (const index of offStore.indexes) {
              objectStore.createIndex(index.name, index.keyPath, index.options);
            }
          }
        }
      };

      request.onsuccess = (e) => {
        if (existed) {
          resolve(null);
          return;
        }

        const db = (e.target as any).result as IDBDatabase;
        resolve(new OfflineDB(db));
      };      
    });
  }

  /**
   * @returns the existing Database or null if the database did not exist.
   */
  public static open(): Promise<OfflineDB | null> {
    return new Promise((resolve, reject) => {
      let existed = true;
      const request = window.indexedDB.open(this.DATABASE_NAME);
      
      request.onerror = (e) => {
        console.error(e);

        reject(e);
      };
      request.onupgradeneeded = (e) => { // Gets called for new database, only
        existed = false;
      };
      request.onblocked = (e) => {
        console.error("Cannot open database. Database is blocked!", e);
      }
      request.onsuccess = (e) => {
        const db = (e.target as any).result as IDBDatabase;

        if (!existed) { // New database! Close again and delete it!
          db.close();

          const delReq = window.indexedDB.deleteDatabase(this.DATABASE_NAME);
          delReq.onsuccess = (e) => {
            resolve(null);
          };
          delReq.onerror = (e) => {
            console.error(e);

            reject(e);
          };
          delReq.onblocked = (e) => {
            console.error("Could not delete new created database. Database is blocked 🤨");

            reject(new Error("Database blocked"));
          };

          return;
        }
        
        resolve(new OfflineDB(db));
      };      
    });
  }

  private static deleteDB(db: IDBDatabase, retryDelete = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = window.indexedDB.deleteDatabase(OfflineDB.DATABASE_NAME);
      request.onerror = (e) => {
        console.error(e);

        reject(e);
      };
      request.onblocked = (e) => {
        if (retryDelete) {
          console.warn("Could not delete database. Database is blocked. Trying to close it and delete it again...");
        
          db.close();

          setTimeout(async () => {
            await this.deleteDB(db, false);

            resolve();
          }, 1000);
        } else {
          console.error("Could not delete database. Database is blocked!");

          reject(new Error("Database blocked"));
        }
      }
      request.onsuccess = (e) => {
        resolve();
      };
    });
  }

  public get objectStores(): string[] {
    return Array.from(this.db.objectStoreNames);
  }

  public async deleteDB(): Promise<void> {
    this.db.close();

    await OfflineDB.deleteDB(this.db);
  }

  public add<T>(offlineStore: DBOfflineStore<T>, data: T | Unarray<T>, multiple = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.db.objectStoreNames.contains(offlineStore.name)) {
        reject(new Error("Unknown store: " + offlineStore.name));
      }

      const transaction = this.db.transaction([offlineStore.name], "readwrite");
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = (e) => {
        console.error(`Cannot add data to store ${offlineStore.name}: ${JSON.stringify(data)}`);
        console.error(e);

        reject(e);
      };

      const objectStore = transaction.objectStore(offlineStore.name);

      if (multiple) {
        for (const item of (data as unknown as Array<any>)) {
          objectStore.add(item);
        }
      } else {
        objectStore.add(data);
      }
    });
  }

  public update<T>(offlineStore: DBOfflineStore<T>, item: Unarray<T>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.db.transaction([offlineStore.name], "readwrite");
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = (e) => {
        console.error(`Cannot update data of store ${offlineStore.name}: ${JSON.stringify(item)}`);
        console.error(e);

        reject(e);
      };

      const objectStore = transaction.objectStore(offlineStore.name);
      if (objectStore.keyPath) { // in-line keys: "put" works as update, if object with keyPath (mostly "id") exists already. Otherwise inserts...
        objectStore.put(item);
      } else { // out-of-line keys: delete all and insert afterwards to update...
        const request = objectStore.clear();
        request.onsuccess = (e) => {
          objectStore.add(item);
        };
      }
    });
  }

  public getAll<T>(offlineStore: DBOfflineStore<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const objectStore = this.db.transaction([offlineStore.name], "readonly").objectStore(offlineStore.name);
      const request = objectStore.getAll();
      request.onerror = (e) => {
        console.error(`cannot get all data of store ${offlineStore.name}`);
        console.error(e);

        reject(e);
      };
      request.onsuccess = (e) => {
        resolve(request.result.length === 1 && !objectStore.keyPath ? request.result[0] : request.result);
      };
    });
  }

  public get<T>(offlineStore: DBOfflineStore<T>, keyValue: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const request = this.db.transaction([offlineStore.name], "readonly").objectStore(offlineStore.name).get(keyValue);
      request.onerror = (e) => {
        console.error(`cannot get data for keyValue ${keyValue} in store ${offlineStore.name}`);
        console.error(e);

        reject(e);
      };
      request.onsuccess = (e) => {
        resolve(request.result);
      };
    });
  }

  public getByIndex<T>(offlineStore: DBOfflineStore<T>, index: string, keyValue: string): Promise<T | T[]> {
    return new Promise<T>((resolve, reject) => {
      const request = this.db.transaction([offlineStore.name], "readonly")
        .objectStore(offlineStore.name)
        .index(index)
        .get(keyValue);

      request.onerror = (e)=> {
        console.error(`getByIndex failed for store ${offlineStore.name} and index ${index}`);
        console.error(e);

        reject(e);
      };
      request.onsuccess = (e) => {
        resolve(request.result);
      };
    });
  }

  public delete<T>(offlineStore: DBOfflineStore<T>, key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.db.transaction([offlineStore.name], "readwrite")
        .objectStore(offlineStore.name)
        .delete(key);

      request.onerror = (e)=> {
        console.error(`cannot delete key ${key} in ${offlineStore.name}`);
        console.error(e);

        reject(e);
      };
      request.onsuccess = (e) => {
        resolve();
      };
    });
  }
}
