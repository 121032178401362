<template>
  <app-content title=""> redirecting... </app-content>
</template>

<script lang="ts">
import AppContent from "@/app/shared/components/app-content/app-content.vue";
import { Component } from "vue-property-decorator";
import { BaseAuthOnlineComponent } from "./shared/components/base-auth-component/base-auth-online-component";

@Component({
  name: "app-home",
  components: {
    AppContent,
  },
})
export default class AppHome extends BaseAuthOnlineComponent {
  async created(): Promise<void> {
    try {
      if (this.isAdmin) {
        this.$router.push({ name: "Plants" });
      } else {
        // TODO: Check for available Pages by permissions and redirect there...
        // if (this.isPilot) {
        //   this.$router.push({ name: "Analyses" });
        // } else {
          const plants = await this.volateqApi.getPlants();

          if (plants.length > 1) {
            this.$router.push({ name: "Plants" });
          } else if (plants.length === 0) {
            this.$router.push({ name: "Analyses" });
          } else {
            this.$router.push({ name: "Plant", params: { id: plants[0].id } });
          }
        // }
      }
    } catch (e) {
      this.showError(e);
    }
  }
}
</script>
