import Vue from "vue";
import VueI18n from "vue-i18n";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueHtmlToPaper from "vue-html-to-paper";
import HotjarPlugin from "@rovahub/vue-hotjar";

import "./main.scss";
import App from "@/app/app.vue";
import router from "@/app/app-routes";
import { DateHelper } from "@/app/shared/services/helper/date-helper";
import { environment } from "./environment/environment";

const VueHtmlToPaperOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 100, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);
Vue.use(VueHtmlToPaper, VueHtmlToPaperOptions);
Vue.use(HotjarPlugin, { // Hotjar records User-Sessions to identify optimization potential.
  siteId: 5078544, // Hotjar Account ID, where the Data is send to.
  debug: false, // adds logs to Browser console.
  version: 6 // Version of the Tracking code. Hotjar will inform us on new releases.
});

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    ...DateHelper.prototype,
  },
});

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: require("./locales/en.json"),
  },
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount("#app");

// Register the service worker for PWA on production only
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      if (environment === "production" || environment === "staging") {
        console.info("Register service worker");

        await navigator.serviceWorker.register(`/service-worker.js`);
      }
    } catch (err) {
      console.error('Service worker registration failed: ', err);
    }
  });
}
