import AppVolaflyDownload from "@/app/no-auth/volafly-download.vue";
import { RouteOptions } from "../types";

const noAuthRoutes: RouteOptions[] = [
  {
    name: "DownloadVolafly",
    path: "/download-volafly",
    component: AppVolaflyDownload,
  },
];

export default noAuthRoutes;
