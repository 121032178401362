import { IEventEmitter } from "../app-event-service/types";

export interface ILockable {
  readonly lockEvent: IEventEmitter;
  locked: boolean;
}

export function lock() {
  return function (target: ILockable, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod: (...args: any) => Promise<void> = descriptor.value;

    descriptor.value = async function(...args: any) {
      const ths = this as ILockable;
      if (ths.locked) {
        await new Promise<void>((resolve, _) => {
          ths.lockEvent.on("unlock", () => resolve());
        });
      }

      try {
        ths.locked = true;
        
        await originalMethod.apply(this, args);
      } finally {
        ths.locked = false;
      }

      ths.lockEvent.emit("unlock");
    };
  };
}