export class MobileStore {
  constructor(
    private mobile = false,
  ) {
    const query = window.matchMedia("screen and (max-width: 1000px)");
    query.addEventListener("change", (ev: MediaQueryListEvent) => {
      this.mobile = ev.matches;
    });

    this.mobile = query.matches;
  }

  public get isMobile(): boolean {
    return this.mobile;
  }
}

export const mobileStore = new MobileStore();