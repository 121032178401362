import { hash } from "./string-helper";

export function getReadableFileSize(size: number): string {
  const sizes = ["KB", "MB", "GB", "TB"];

  let i = 0;
  do {
    size = size / 1024;
  } while (size > 1024 && ++i < sizes.length);

  return `${Math.round(size)} ${sizes[i]}`;
}

export function dataURLtoFile(dataUrl: string, filename?: string): File {
  const byteString = atob(dataUrl.split(',')[1]);
  const buffer = new ArrayBuffer(byteString.length);
  const data = new Uint8Array(buffer);
  for (let i = 0; i < byteString.length; i++) {
    data[i] = byteString.charCodeAt(i);
  }

  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  
  return new File([buffer], filename || hash(dataUrl), { type: mimeString });
}
