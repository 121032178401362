import { appLocalStorage } from "../services/app-storage/app-storage";
import { PlantSchema } from "../services/volateq-api/api-schemas/plant-schema";

export class OfflineStore {
  // https://github.com/microsoft/TypeScript/issues/37487 That is the reason for "_plant"

  constructor(
    private offline = false,
    private _plant: PlantSchema | null = null,
    private _error: string | null = null,
  ) {
    this.offline = appLocalStorage.getItem("offline") || false;
    this._plant = (appLocalStorage.getItem("offlinePlant") || null) as PlantSchema | null;
  }

  public goOffline(plant: PlantSchema | null) { 
    appLocalStorage.setItem("offline", true);
    appLocalStorage.setItem("offlinePlant", plant);

    this.offline = true;
    this._plant = plant;
    this._error = null;
  }

  public goOnline() {
    appLocalStorage.setItem("offline", false);
    appLocalStorage.setItem("offlinePlant", null);

    this.offline = false;
    this._plant = null;
    this._error = null;
  }

  public offlineError(msg: string) {
    this._error = msg;
  }

  public get isOffline(): boolean {
    return this.offline;
  }

  public get plant(): PlantSchema | null {
    return this._plant;
  }

  public get error(): string | null {
    return this._error;
  }
}

export const offlineStore = new OfflineStore();