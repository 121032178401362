import { createDecorator } from "vue-class-component";


export function VDebounce(timeout: number) {
  return createDecorator((options, key) => {
    if (options.methods) {
      const originalMethod = options.methods[key];
      options.methods[key] = function (...args) {
        const timer = this["timer"] || null;

        if (timer) {
          clearTimeout(timer);
          this["timer"] = null;
        }

        this["timer"] = setTimeout(async () => {
          await originalMethod.apply(this, args);
        }, timeout);
      }
    }
  });
}

export function Debounce(timeout = 300) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod: (...args: any) => Promise<void> = descriptor.value;
    descriptor.value = async function(...args: any) {
      const timer = this["timer"] || null;

      if (timer) {
        clearTimeout(timer);
        this["timer"] = null;
      }

      this["timer"] = setTimeout(async () => {
        await originalMethod.apply(this, args);
      }, timeout);
    };
  };
}

export async function waitFor(ms: number) {
  await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
}
