import { appLocalStorage } from "../services/app-storage/app-storage";

export class ProtectionStore {
  constructor(
    private _botProtectionToken: string = "",
  ) {
    this._botProtectionToken = appLocalStorage.getItem("botProtectionToken");
  }

  public updateBotProtectionToken(botProtectionToken: string) {
    appLocalStorage.setItem("botProtectionToken", botProtectionToken)
    this._botProtectionToken = botProtectionToken;
  }

  public get botProtectionToken(): string {
    return this._botProtectionToken;
  }
}

export const protectionStore = new ProtectionStore();