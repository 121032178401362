import { EventEmitter } from "@/app/shared/services/app-event-service/event-emitter";
import { SequentialEventEmitter } from "@/app/shared/services/app-event-service/sequential-event-emitter";
import { ILockable, lock } from "@/app/shared/services/helper/lock-helper";
import { CustomComponentPropertySchema } from "@/app/shared/services/volateq-api/api-schemas/custom-component-property-schema";
import { PlantSchema } from "@/app/shared/services/volateq-api/api-schemas/plant-schema";
import { VolateqOfflineApi } from "@/app/shared/services/volateq-api/offline/volateq-offline-api";

export class CcpService implements ILockable {
  private static ccpServices: Record<string, CcpService> = {};
  public static get(plant: PlantSchema): CcpService {
    if (!(plant.id in CcpService.ccpServices)) {
      CcpService.ccpServices[plant.id] = new CcpService(plant);
    }

    return CcpService.ccpServices[plant.id];
  }

  public locked = false;
  public readonly lockEvent: EventEmitter = new EventEmitter();

  private _ccps: CustomComponentPropertySchema[] | null = null;

  constructor(private readonly plant: PlantSchema) {}
  
  @lock()
  async update(force = false) {
    if (!force && this._ccps !== null) { // Keep this! Because this is a "locked" method
      return;
    }
    
    this._ccps = await VolateqOfflineApi.get(this.plant).getCustomComponentProperties(this.plant.id); 
  }
  
  async getCcps(): Promise<CustomComponentPropertySchema[]> {
    if (this._ccps === null) {
      await this.update();
    }

    return this._ccps!;
  }

  async getCcpsByComponent(componentId: number): Promise<CustomComponentPropertySchema[]> {
    return (await this.getCcps()).filter(c => c.component.id === componentId);
  }

  getCcp(ccpId: string): CustomComponentPropertySchema | undefined {
    return this._ccps?.find(ccp => ccp.id === ccpId);
  }

  

  public static get defaultColor(): string {
    return "#cccccc";
  }
}