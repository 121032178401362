<template>
  <app-content title="Page not found" subtitle="Where am I?" :showHeader="isAuthenticated">
    <div class="page-not-found">
      <img src="/images/brdrone.png" srcset="/images/brdrone.webp, /images/brdrone.png" alt="page not found" />
    </div>
  </app-content>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import AppContent from "@/app/shared/components/app-content/app-content.vue";
import { BaseAuthOnlineComponent } from "../base-auth-component/base-auth-online-component";

@Component({
  name: "app-page-not-found",
  components: {
    AppContent,
  },
})
export default class AppPageNotFound extends BaseAuthOnlineComponent {
  async created() {
    // Redirects to login page is user is not loggedIn anymore
    await this.volateqApi.isLoggedIn();
  }

  get isAuthenticated() {
    return this.store.auth.isAuthenticated;
  }
}
</script>

<style lang="scss">
.page-not-found > img {
  width: 300px;
  margin: auto;
  display: block;
  margin-top: 50px;
}
</style>
