import { ApiPermissions } from "../../services/volateq-api/api-permissions";
import { CustomerNameSchema } from "../../services/volateq-api/api-schemas/customer-schemas";
import { BaseComponent } from "../base-component/base-component";

export abstract class BaseAuthComponent extends BaseComponent {
  get isAdmin(): boolean {
    return !this.isHiddenSuperAdmin && this.store.auth.isVolateqAdmin;
  }

  get isHiddenSuperAdmin(): boolean {
    return this.store.auth.isHiddenAdmin;
  }

  get selectedCustomer(): CustomerNameSchema | undefined {
    if (this.isAdmin || this.isHiddenSuperAdmin) {
      return this.store.auth.customer;
    }
    
    return undefined;
  }

  get p(): typeof ApiPermissions {
    return ApiPermissions;
  }

  hasPerm(...p: ApiPermissions[]): boolean {
    return this.store.auth.hasPermission(...p);
  }

  hasAdminPerm(...p: ApiPermissions[]): boolean {
    return this.isAdmin && this.hasPerm(...p);
  }

  get hasPermPlantView(): boolean {
    return this.hasPerm(ApiPermissions.READ_ANALYSIS_RESULT) ||
      this.hasPerm(ApiPermissions.READ_DIGITAL_TWIN_VISUALIZATION) ||
      this.hasPerm(ApiPermissions.READ_OBSERVATION) ||
      this.hasPerm(ApiPermissions.READ_ALL_PLANT);
  }
}
