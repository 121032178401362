import { RouteConfig } from "vue-router";
import { ApiPermissions } from "./shared/services/volateq-api/api-permissions";

export enum PermissionOperator {
  AND = "and",
  OR = "or"
}

export interface RouteMeta {
  permission?: ApiPermissions[];
  permOperator?: PermissionOperator;
}

export type RouteOptions = RouteConfig & {
  meta?: RouteMeta;
  children?: RouteOptions[]
  title?: string;
  subtitle?: string;
}