import { ApiComponent } from "../api-components/api-components";
import { ApiKeyFigure } from "../api-key-figures";
import { ApiPI } from "../api-performance-indicator";
import { GeoVisualQuery } from "../api-requests/geo-visual-query-requests";
import { ObservFilterValue } from "../api-requests/observation-requests";
import { AnalysisForViewSchema, SimpleAnalysisSchema } from "../api-schemas/analysis-schema";
import { CustomComponentPropertySchema } from "../api-schemas/custom-component-property-schema";
import { EnabledPiFieldSchema } from "../api-schemas/enabled-pi-field-schema";
import { ObservationColumn, ObservationSchema, SummerizedDates } from "../api-schemas/observation-schema";
import { PerformanceIndicatorSchema } from "../api-schemas/performance-indicator";
import { TableResultSchema } from "../api-schemas/table-result-schema";
import { UserSchema } from "../api-schemas/user-schemas";
import { DBOfflineStore } from "./offline-store";
import { ObservationGeoJson, ObservationSchemaReq } from "./types";

export class DBOfflineStores {
  public static readonly ANALYSES_FOR_VIEW = new DBOfflineStore<AnalysisForViewSchema[]>("analyses-for-view");
  public static readonly LAST_ANALYSIS = new DBOfflineStore<SimpleAnalysisSchema>("last-analysis");
  public static readonly ENABLED_PI_FIELDS = new DBOfflineStore<EnabledPiFieldSchema[]>("enabled-pi-fields");
  public static readonly CUSTOM_COMPONENT_PROPERTIES = new DBOfflineStore<CustomComponentPropertySchema[]>("custom-component-properties");
  public static readonly ME = new DBOfflineStore<UserSchema>("me");
  public static readonly MAP_TILES = new DBOfflineStore<Record<string, string>>("map-tiles");
  public static readonly SUMMERIZED_DATES = new DBOfflineStore<SummerizedDates>("summerized-dates");
  public static readonly PERFORMANCE_INDICATORS = new DBOfflineStore<PerformanceIndicatorSchema[]>("performance-indicators");

  public static readonly NEW_OBSERVATIONS_ = "new-observations_";
  public static NEW_OBSERVATIONS(componentId: ApiComponent) {
    return new DBOfflineStore<ObservationSchemaReq[]>(this.NEW_OBSERVATIONS_ + componentId, { keyPath: "id" }, [
      { name: "dt_id", keyPath: "fieldgeometry_component.dt_id", options: { unique: false } }
    ]);
  }
  public static readonly NEW_OBSERVATIONS_GEO_JSON = new DBOfflineStore<ObservationGeoJson[]>(
    "new-observations-geojson", { keyPath: "id" });

  public static readonly OBSERVATION_COLUMNS_ = "observations-columns_";
  public static OBSERVATION_COLUMNS(componentId: ApiComponent) {
    return new DBOfflineStore<ObservationColumn[]>(
      this.OBSERVATION_COLUMNS_ + componentId,
      { keyPath: "id" },
    );
  }

  public static readonly OBSERVATIONS_ = "observation_";
  public static OBSERVATIONS(componentId: ApiComponent) {
    return new DBOfflineStore<ObservationSchema[]>(
      this.OBSERVATIONS_ + componentId,
      { keyPath: "id" },
      [ { name: "dt_id", keyPath: "fieldgeometry_component.dt_id", options: { unique: false } }],
    );
  }
  public static ANALYSIS_RESULTS<T>(componentId: ApiComponent) {
    return new DBOfflineStore<TableResultSchema<T>>("analysis-results_" + componentId, { keyPath: "fieldgeometry_component.dt_id" });
  }
  public static GEO_VISUAL_COMPONENTS(componentId: ApiComponent) {
    return new DBOfflineStore<any>("geo-visual-component_" + componentId);
  }
  public static GEO_VISUAL_COMPONENTS_CSV(componentId: ApiComponent) {
    return new DBOfflineStore<string>("geo-visual-component-csv_" + componentId);
  }
  public static GEO_VISUAL_KEY_FIGURES(
    analysisResultId: string,
    keyFigureId: ApiKeyFigure,
    geoVisualQuery?: GeoVisualQuery
  ) {
    return new DBOfflineStore<any>("geo-visual-key-figures_" + analysisResultId + "_" + keyFigureId + "_" + JSON.stringify(geoVisualQuery));
  }
  public static GEO_VISUAL_OBSERV_CCP(
    ccpId: string,
    fromDate: string,
    toDate: string,
    filterValue: ObservFilterValue
  ) {
    return new DBOfflineStore<any>("geo-visual-observ-ccp_" + ccpId + "_" + fromDate + "_" + toDate + "_" + JSON.stringify(filterValue));
  }
  public static GEO_VISUAL_OBSERV_PI(
    piId: ApiPI,
    fromDate: string,
    toDate: string,
    filterValue: ObservFilterValue
  ) {
    return new DBOfflineStore<any>("geo-visual-observ-pi_" + piId + "_" + fromDate + "_" + toDate + "_" + JSON.stringify(filterValue));
  }
}