import Vue from "vue";

export const sidebars = ["analyses", "observations"] as const;
export type SidebarNames = typeof sidebars[number];

export const mapSidebars = ["analysesSelection", "observationsSelection"] as const;
export type MapSidebarNames = typeof mapSidebars[number];

export type AllSidebarNames = SidebarNames | MapSidebarNames;
export type Sidebars = { [k in AllSidebarNames]: boolean; };

export class SidebarStore {
  private sidebars: Sidebars;
  private _lastActiveSidebarName: SidebarNames | undefined;

  constructor() {
    this.sidebars = { ...([...sidebars, ...mapSidebars].reduce((acc, name) => ({ ...acc, [name]: false }), {}) as Sidebars) };
    this._lastActiveSidebarName = undefined;
  }

  public toggle(sidebarName: AllSidebarNames) {
    this.set({ name: sidebarName, state: !this.sidebars[sidebarName] });
  }

  public set(newState: { name: AllSidebarNames, state: boolean}) {
    this.sidebars[newState.name] = newState.state;

    if (sidebars.includes(newState.name as SidebarNames)) { // TS forced me to write this... 🤮
      this._lastActiveSidebarName = newState.name as SidebarNames;
    }
  }

  public get lastActiveSidebarName(): SidebarNames | undefined {
    return this._lastActiveSidebarName;
  }

  public get analysesSelection(): boolean {
    return this.sidebars.analysesSelection;
  }

  public get observationsSelection(): boolean {
    return this.sidebars.observationsSelection;
  }

  public get analyses(): boolean {
    return this.sidebars.analyses;
  }

  public get observations(): boolean {
    return this.sidebars.observations;
  }
}

export const sidebarStore = new SidebarStore();