import Vue from "vue";
import Router from "vue-router";

import authRoutes from "@/app/auth/auth-routes";
import analysisRoutes from "@/app/analysis/analysis-routes";
import settingsRoutes from "@/app/settings/settings-routes";
import plantRoutes from "@/app/plant/plant-routes";
import docRoutes from "@/app/doc/doc-routes";
import AppPageNotFound from "@/app/shared/components/page-not-found/page-not-found.vue";
import AppHome from "@/app/home.vue";
import noAuthRoutes from "@/app/no-auth/no-auth-routes";
import { store } from "./shared/stores/store";
import { PermissionOperator, RouteMeta, RouteOptions } from "./types";

Vue.use(Router);

const appRoutes: RouteOptions[] = [
  {
    path: "*",
    name: "page-not-found",
    component: AppPageNotFound,
  },
  {
    path: "/",
    name: "Home",
    component: AppHome,
  },
];

const router = new Router({
  mode: "history",
  routes: [
    ...appRoutes,
    ...authRoutes,
    ...analysisRoutes,
    ...settingsRoutes,
    ...plantRoutes,
    ...docRoutes,
    ...noAuthRoutes,
  ],
});

// well... I admit this naming is confusing!
// authRoutes are necessery for the authorization. There are not auth protected!
const nonAuthProtectRoutes = [...authRoutes.map(a => a.name), ...noAuthRoutes.map(a => a.name)];

router.beforeEach((to, from, next) => {
  if (!store.auth.isAuthenticated) {
    if (nonAuthProtectRoutes.indexOf(to.name || "") === -1) {
      next({ name: "Login", query: { dest: to.fullPath }});
      return;
    }
  } else {
    if (store.offline.isOffline) {
      if (to.name !== "Plant") {
        next({ name: "Plant" });
        return;
      }
    }

    if (to.name === "Login") {
      next({ name: "Home" });
      return;
    }

    const meta = to.meta as RouteMeta | undefined;
    if (meta?.permission) {
      let pageNotFound = false;
      if (meta.permOperator === PermissionOperator.OR) {
        pageNotFound = !meta.permission.find(p => store.auth.hasPermission(p))
      } else {
        pageNotFound = !store.auth.hasPermission(...meta.permission);
      }

      if (pageNotFound) {
        next({ name: "page-not-found" });
        return;
      }
    }
  }

  next();
});

export default router;
