<template>
  <div>
    <strong>August 2024 (v8.11.0)</strong>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseReleaseNotesComponent from "./base-release-notes-component"

@Component({
  name: "app-release-notes-v11",
})
export default class AppReleaseNotesV11 extends BaseReleaseNotesComponent {
  releaseNotesVersion = "v11"

  async mounted() {
  }
}
</script>
