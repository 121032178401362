import { ApiPermissions } from "../shared/services/volateq-api/api-permissions";
import { RouteOptions } from "../types";

const AppDocFiles = () => import("@/app/doc/doc-files.vue");

const docRoutes: RouteOptions[] = [
  {
    name: "DocFiles",
    path: "/doc/files",
    component: AppDocFiles,
    meta: {
      permission: [
        ApiPermissions.LIST_DOC_FILE,
        ApiPermissions.DOWNLOAD_DOC_FILE,
      ],
    }
  },
];

export default docRoutes;
