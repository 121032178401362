const AppAnalysis = () => import("@/app/analysis/analysis.vue");
const AppNewAnalysis = () => import("@/app/analysis/new-analysis/new-analysis.vue");
const AppEditAnalysis = () => import("@/app/analysis/edit-analysis/edit-analysis.vue");
import { ApiPermissions } from "../shared/services/volateq-api/api-permissions";
import { PermissionOperator, RouteOptions } from "../types";

const anaylsisRoutes: RouteOptions[] = [
  {
    name: "Analyses",
    path: "/analyses",
    component: AppAnalysis,
    meta: {
      permission: [ApiPermissions.READ_ANALYSIS],
    },
  },
  {
    name: "AnalysisNew",
    path: "/analysis/new",
    component: AppNewAnalysis,
    meta: {
      permission: [ApiPermissions.CREATE_ANALYSIS, ApiPermissions.UPLOAD_ANALYSIS_FILE]
    },
  },
  {
    name: "EditAnalysis",
    path: "/analysis/:id",
    component: AppEditAnalysis,
    meta: {
      permission: [
        ApiPermissions.UPDATE_ANALYSIS,
        ApiPermissions.UPLOAD_ANALYSIS_FILE,
        ApiPermissions.DOWNLOAD_ANALYSIS_FILE,
        ApiPermissions.READ_FLIGHT_CAMPAIGN,
      ],
      permOperator: PermissionOperator.OR,
    }
  },
  {
    name: "UploadForAnalysis",
    path: "/analysis/:id",
    component: AppEditAnalysis,
    meta: {
      permission: [ApiPermissions.UPLOAD_ANALYSIS_FILE],
      hash: "#upload",
    }
  },
  {
    name: "EditAnalysisInvoice",
    path: "/analysis/:id",
    component: AppEditAnalysis,
    meta: {
      role: [ApiPermissions.UPDATE_ANALYSIS_INVOICE],
      hash: "#invoice",
    }
  },
];

export default anaylsisRoutes;
