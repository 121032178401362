import { appLocalStorage } from "@/app/shared/services/app-storage/app-storage";
import { CustomerNameSchema } from "@/app/shared/services/volateq-api/api-schemas/customer-schemas";
import { ApiPermissions } from "../services/volateq-api/api-permissions";

export interface AuthState {
  token: string;
  permissions: ApiPermissions[];
  customer?: CustomerNameSchema;
  hiddenAdmin?: boolean;
  isAdmin?: true;
}

const STORAGE_KEY = "auth_token";

export class AuthStore {
  private state: AuthState;
  private permissions: Set<ApiPermissions> = new Set();

  constructor() {
    this.state = appLocalStorage.getItem(STORAGE_KEY) || { token: "", permissions: [] };
    this.permissions = new Set(this.state.permissions);
  }

  public updateToken(newState: AuthState) {
    this.state.token = newState.token;
    this.state.permissions = newState.permissions;
    this.state.customer = newState.customer;
    this.state.isAdmin = newState.isAdmin;

    this.permissions = new Set(this.state.permissions);

    appLocalStorage.setItem(STORAGE_KEY, this.state);
  }

  public updateCustomer(customer: CustomerNameSchema | undefined) {
    this.state.customer = customer;

    appLocalStorage.setItem(STORAGE_KEY, this.state);
  }

  public updateHidden(isHidden: boolean) {
    if (!this.state.isAdmin) {
      return;
    }

    this.state.hiddenAdmin = isHidden;

    appLocalStorage.setItem(STORAGE_KEY, this.state);
  }

  public get isAuthenticated(): boolean {
    return !!this.state.token;
  }

  public get isVolateqAdmin(): boolean {
    return !!this.state.isAdmin;
  }

  public get isHiddenAdmin(): boolean {
    return !!this.state.hiddenAdmin;
  }

  public get customer(): CustomerNameSchema | undefined {
    return this.state.customer;
  }

  public get token(): string {
    return this.state.token;
  }

  public hasPermission(...perms: ApiPermissions[]): boolean {
    for (const p of perms) {
      if (!this.permissions.has(p)) {
        return false;
      }
    }

    return true;
  }
}

export const authStore = new AuthStore();
