export enum ApiErrors {
  INVALID_TOKEN = "INVALID_TOKEN",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
  EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
  RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND",
  BAD_REQUEST = "BAD_REQUEST",
  DISCONNECT = "DISCONNECT",
  PERMISSION_RELOAD_REQUIRED = "PERMISSION_RELOAD_REQUIRED",
}

export interface ApiException {
  error: ApiErrors | string;
  message?: string;
  details?: string;
}
